<ng-container *transloco="let t">
  <nav (click)="onNavItemClick($event)">
    <a class="ui-item-link" [routerLink]="['/']">
      <pxw-icon class="nav-icon" icon="home"></pxw-icon>
      <span class="nav-text">{{ t('sideMenu.home') }}</span>
    </a>
    <button *ngIf="infoEnabled" class="ui-item-link" (click)="onClickInfo()">
      <pxw-icon class="nav-icon" icon="info"></pxw-icon>
      <span class="nav-text">{{ t('sideMenu.info') }}</span>
    </button>
    <button *ngIf="hasAddressDetails" class="ui-item-link" (click)="onClickLocation()">
      <pxw-icon class="nav-icon" icon="map-marker"></pxw-icon>
      <span class="nav-text">{{ t('sideMenu.location') }}</span>
    </button>
    <a
      *ngIf="establishment.links?.website"
      class="ui-item-link"
      target="_blank"
      [href]="establishment.links.website"
    >
      <pxw-icon class="nav-icon" icon="web"></pxw-icon>
      <span class="nav-text">{{ t('sideMenu.website') }}</span>
    </a>
    <a
      *ngIf="establishment.links?.instagram"
      class="ui-item-link"
      target="_blank"
      [href]="establishment.links.instagram"
    >
      <pxw-icon class="nav-icon" icon="instagram"></pxw-icon>
      <span class="nav-text">{{ t('sideMenu.instagram') }}</span>
    </a>
    <a
      *ngIf="establishment.links?.facebook"
      class="ui-item-link"
      target="_blank"
      [href]="establishment.links.facebook"
    >
      <pxw-icon class="nav-icon" icon="facebook"></pxw-icon>
      <span class="nav-text">{{ t('sideMenu.facebook') }}</span>
    </a>
    <a
      *ngIf="establishment.links?.twitter"
      class="ui-item-link"
      target="_blank"
      [href]="establishment.links.twitter"
    >
      <pxw-icon class="nav-icon" icon="twitter"></pxw-icon>
      <span class="nav-text">{{ t('sideMenu.twitter') }}</span>
    </a>
    <a
      *ngIf="establishment.links?.tiktok"
      class="ui-item-link"
      target="_blank"
      [href]="establishment.links.tiktok"
    >
      <pxw-icon class="nav-icon" icon="tiktok"></pxw-icon>
      <span class="nav-text">{{ t('sideMenu.tiktok') }}</span>
    </a>

    <!-- FEATURE: WHITE_LABEL_BRANDING -->
    <a
      *ngIf="!hasFeatureWhiteLabelBranding"
      class="side-menu__pedix ui-item-link"
      [href]="landingUrl"
      target="_blank"
      (click)="onInterestInPedix()"
    >
      {{ t('sideMenu.pedix') }}
    </a>
  </nav>

  <pxw-modal-dialog #infoDialog type="modal" [showCloseButton]="true" *ngIf="infoEnabled">
    <ng-template #modalContent>
      <div class="dialog-content">
        <div *ngIf="workingDaysEnabled" class="dialog-section working-hours">
          <p class="title">{{ t('sideMenu.workingHours') }}</p>
          <pxw-working-hours-message-details
            [workingDays]="establishment?.workingDays"
          ></pxw-working-hours-message-details>
        </div>

        <div *ngIf="hasContactInfo" class="dialog-section contact">
          <p class="title">{{ t('sideMenu.contact') }}</p>
          <a
            *ngIf="establishment.phone"
            class="ui-item-link phone-link"
            [href]="getLinkWhastapp()"
            [target]="whatsappLinkHttpTarget"
          >
            <pxw-icon class="whatsapp-icon" icon="whatsapp" size="xs"></pxw-icon>
            <span>+{{ establishment.phone }}</span>
          </a>
          <a
            *ngIf="establishment.additionalPhone"
            class="ui-item-link phone-link"
            href="tel:+{{ establishment.additionalPhone }}"
            target="_blank"
          >
            <pxw-icon icon="phone" size="xs"></pxw-icon>
            <span>+{{ establishment.additionalPhone }}</span>
          </a>
        </div>
      </div>
    </ng-template>
  </pxw-modal-dialog>

  <pxw-modal-dialog
    #locationDialog
    [title]="t('sideMenu.location')"
    type="modal"
    [showCloseButton]="true"
    *ngIf="hasAddressDetails"
  >
    <ng-template #modalContent>
      <p>{{ establishment.addressDetails.fullAddress }}</p>
      <p class="address-references" *ngIf="establishment.addressReferences">
        {{ establishment.addressReferences }}
      </p>
      <div
        class="dialog-content establishment-map"
        *ngIf="establishment.addressDetails.coordinates"
      >
        <a class="map-link ui-item-link" [href]="establishmentMapUrl" target="_blank">
          <div class="map-container">
            <pxw-here-maps-static
              [center]="establishment.addressDetails.coordinates"
              [marker]="{
                center: establishment.addressDetails.coordinates,
                label: establishment.name
              }"
              [zoom]="17"
              [width]="400"
              [height]="400"
              [alt]="t('shippingOptions.establishmentLocation')"
            />
          </div>
          <div class="link-text">
            <pxw-icon icon="external-link"></pxw-icon>
            {{ t('sideMenu.openInGoogleMaps') }}
          </div>
        </a>
      </div>
    </ng-template>
  </pxw-modal-dialog>
</ng-container>
