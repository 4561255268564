<ng-container *transloco="let t">
  @if (isTemporaryClosed) {
    <div class="closed-message">
      <span class="icon">⛔</span>
      <div class="text-details">
        {{ t('workingHours.closedMessageIntro') }}
        <ng-container></ng-container>
        @if (temporaryClosureMessage) {
          <div
            #message
            class="subtitle"
            (click)="hasMessageOverflow() && fullClosingMessageDialog.open()"
          >
            {{ temporaryClosureMessage }}
          </div>
        }
      </div>
    </div>
    <pxw-modal-dialog
      #fullClosingMessageDialog
      [title]="t('workingHours.closedMessageIntro')"
      type="alert"
    >
      <pxw-icon icon="ban" />
      <p>{{ temporaryClosureMessage }}</p>
    </pxw-modal-dialog>
  } @else {
    @if (isClosed || isAboutToClose) {
      <button class="closed-message" (click)="displayWorkingHours()">
        @if (isAboutToClose) {
          <span class="icon">🕓</span>
          <div class="text-details">
            {{ t('workingHours.aboutToCloseMessageIntro') }}
            <div #message class="subtitle">{{ t('workingHours.closedMessageSubtitle') }}</div>
          </div>
        } @else {
          <span class="icon">😴</span>
          <div class="text-details">
            {{ t('workingHours.closedMessageIntro') }}
            <div #message class="subtitle">{{ t('workingHours.closedMessageSubtitle') }}</div>
          </div>
        }
      </button>
    }
  }

  @if ((isClosed || isAboutToClose) && !isSsr) {
    <pxw-modal-dialog #workingHoursDialog [title]="t('workingHours.dialogTitle')" type="alert">
      <pxw-icon icon="info" />
      @if (workingDays?.isActive) {
        <pxw-working-hours-message-details
          [workingDays]="workingDays"
        ></pxw-working-hours-message-details>
      }
    </pxw-modal-dialog>
  }
</ng-container>
