<ng-container
  *ngTemplateOutlet="
    layoutTemplate || defaultLayoutTemplate;
    context: { horizontalSliderTemplate, itemListTemplate }
  "
/>

<ng-template #defaultLayoutTemplate>
  <ng-container *ngTemplateOutlet="horizontalSliderTemplate" />

  <ng-container *ngTemplateOutlet="itemListTemplate" />
</ng-template>

<ng-template #horizontalSliderTemplate>
  <pxw-horizontal-slider [ngClass]="{ 'visible-hidden': !displayHorizontalSlider }">
    @for (itemListGroup of itemListGroups; track itemListGroup.group.id) {
      <pxw-horizontal-slider-item [id]="itemListGroup.group.id">
        <pxw-button
          [color]="'theme2-contrast'"
          [appareance]="itemListGroup.group.id === _selectedGroupId ? 'solid' : 'outline'"
          [rounded]="true"
          size="md"
          (click)="onSliderItemClick(itemListGroup.group.id)"
        >
          {{ itemListGroup.group.name }}
        </pxw-button>
      </pxw-horizontal-slider-item>
    }
  </pxw-horizontal-slider>
</ng-template>

<ng-template #itemListTemplate>
  <div
    class="item-list__wrapper"
    #scrollTarget
    pxwScrollBreaksWrapper
    pxwDetectScroll
    [scrollOffset]="1"
    [invalidateValue]="itemListGroups"
    (scroll.zoneless)="onProductListScroll($event)"
    (breakItemActiveChange)="onBreakItemActiveChange($event)"
    (scrollStateChange)="onScrollStateChange($event)"
  >
    <pxw-virtual-list-wrapper [scrollTarget]="scrollTarget" [invalidateValue]="itemListGroups">
      @if (itemListGroups.length > 0) {
        @for (itemListGroup of itemListGroups; track itemListGroup.group.id) {
          <div class="item-list__group" pxwScrollBreakItem [id]="itemListGroup.group.id">
            @if (displayHeadings) {
              <ng-container
                *ngTemplateOutlet="
                  groupHeadingTemplate || defaultGroupHeadingTemplate;
                  context: { group: itemListGroup.group, groupItem: itemListGroup }
                "
              ></ng-container>
              <ng-template #defaultGroupHeadingTemplate>
                <h2 class="item-list__group-heading">
                  <span class="item-list__group-title">
                    {{ itemListGroup.group.name }}
                    <span class="item-list__group-count"
                      >({{ itemListGroup.indexedItems.length }})</span
                    >
                  </span>
                </h2>
              </ng-template>
            }

            <div class="item-list-grid">
              @for (indexedProduct of itemListGroup.indexedItems; track indexedProduct.item.id) {
                <pxw-virtual-list-item
                  [id]="indexedProduct.listIndex + '-' + indexedProduct.item.id"
                  [title]="indexedProduct.item.name"
                  [forceRender]="indexedProduct.listIndex < initialRenderItemsQty"
                  [ngStyle]="{ 'min-height.px': itemHeight }"
                >
                  <ng-template #itemTemplate>
                    <ng-container
                      *ngTemplateOutlet="
                        listItemTemplate;
                        context: {
                          group: itemListGroup.group,
                          item: indexedProduct.item,
                          index: indexedProduct.listIndex
                        }
                      "
                    ></ng-container>
                  </ng-template>
                </pxw-virtual-list-item>
              }
            </div>
          </div>
        }
      } @else {
        <div class="item-list__empty-state">
          {{ emptyListText }}
        </div>
      }
    </pxw-virtual-list-wrapper>
  </div>
</ng-template>
