<ng-container *transloco="let t">
  <div
    class="option-item checkbox"
    [ngClass]="{
      'without-stock': (item.id | pxwStockInfo | async)?.isOutOfStock,
      checked: isChecked,
    }"
  >
    <div class="option-body">
      @if (displaySku && item.sku) {
        <p class="item-sku">{{ item.sku }}</p>
      }
      <p class="item-name">
        <span class="item-name-text" (click)="onItemNameClick($event)">{{
          item | propInSelectedLanguage: 'name' : selectedLanguage : true
        }}</span>
        @if (item.image) {
          <pxw-icon
            icon="image"
            size="md"
            class="item-image"
            (click)="onItemImageClick($event)"
          ></pxw-icon>
        }
      </p>
      @if (item.description) {
        <p class="item-description help">
          {{ item | propInSelectedLanguage: 'description' : selectedLanguage }}
        </p>
      }
      <pxw-available-stock
        [stockInfo]="item.id | pxwStockInfo | async"
        [cartItemId]="cartItemId"
        [optionId]="optionId"
        [stockDisplayCondition]="stockDisplayCondition"
        [stockDisplayConditionValue]="stockDisplayConditionValue"
        size="xs"
      ></pxw-available-stock>
    </div>
    @if (displayItemQuantities) {
      <pxw-counter
        class="item-counter"
        size="sm"
        [inverted]="true"
        [count]="count"
        (countChange)="onCountChange($event)"
        [min]="0"
        [max]="
          (item.id | pxwStockInfo | async)?.availableStock || 0 < maxCount
            ? (item.id | pxwStockInfo | async)?.availableStock
            : maxCount
        "
        [decrementDisabled]="count === 0"
        [incrementDisabled]="disableIncrement"
        color="yellow"
      ></pxw-counter>
    } @else {
      <span class="item-counter"></span>
    }
    @if (item.price) {
      <pxw-product-price
        class="item-price"
        [price]="item.price"
        [priceDiscount]="item.priceDiscount"
        layout="option-item"
      />
    }
  </div>
</ng-container>
