<ng-container *transloco="let t">
  <pxw-alert class="announcement" type="info" size="sm" [rounded]="false">
    <pxw-icon icon="advertisement" />
    @if (announcements().title) {
      <div class="announcement-title">
        <b>{{ announcements().title }}</b>
      </div>
    }
    @if (announcements().body) {
      <div #messageBody class="announcement-body" (click)="announcementDialog.open()">
        {{ announcements().body }}
      </div>
    }
    <pxw-icon
      class="icon-close"
      icon="close"
      size="xs"
      color="inherit"
      (click)="announcementsHidden.emit()"
    />
  </pxw-alert>
  <pxw-modal-dialog #announcementDialog [title]="announcements().title || 'Anuncio'" type="alert">
    <pxw-icon icon="advertisement" />
    <p>{{ announcements().body }}</p>
  </pxw-modal-dialog>
</ng-container>
