<ng-container *transloco="let t">
  <div
    class="option-item radio"
    [ngClass]="{ 'without-stock': (item.id | pxwStockInfo | async)?.isOutOfStock }"
  >
    <div class="option-body">
      @if (displaySku && item.sku) {
        <p class="item-sku">{{ item.sku }}</p>
      }
      <p class="item-name">
        <span>{{ item | propInSelectedLanguage: 'name' : selectedLanguage : true }}</span>
        @if (item.image) {
          <pxw-icon
            icon="image"
            size="sm"
            class="item-image"
            (click)="onItemImageClick($event)"
          ></pxw-icon>
        }
      </p>
      @if (item.description) {
        <p class="item-description help">
          {{ item | propInSelectedLanguage: 'description' : selectedLanguage }}
        </p>
      }
      <pxw-available-stock
        [stockInfo]="item.id | pxwStockInfo | async"
        [cartItemId]="cartItemId"
        [optionId]="optionId"
        [stockDisplayCondition]="stockDisplayCondition"
        [stockDisplayConditionValue]="stockDisplayConditionValue"
        size="xs"
      ></pxw-available-stock>
    </div>
    @if (item.price) {
      <pxw-product-price
        class="item-price"
        [price]="item.price"
        [priceDiscount]="item.priceDiscount"
        layout="option-item"
      />
    }
  </div>
</ng-container>
