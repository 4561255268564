import { Component, OnInit, PLATFORM_ID, ViewChild, inject } from '@angular/core';
import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import { StockService } from '@pedix-workspace/pedixapp-stock';
import { StockInfo } from '@pedix-workspace/shared-stock';
import { Router, ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  CartItem,
  CartItemCategory,
  EndOrderDetails,
  isCategoryAvailable,
} from '@pedix-workspace/utils';
import { isPlatformServer, AsyncPipe } from '@angular/common';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { EstablishmentWorkingHoursService } from '../../main/establishment-working-hours.service';
import { CategoryAvailabilityDialogComponent } from '../category-availability-dialog/category-availability-dialog.component';
import { ApiV1_Category } from '@pedix-workspace/api-v1';
import { StockIsOutOfStockPipe, StockInfoPipe } from '@pedix-workspace/pedixapp-stock';
import { CurrencyFormatPipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';
import { ResumeItemComponent } from '@pedix-workspace/pedixapp-shared-catalog';
import {
  IconAlertComponent,
  IconBackComponent,
  IconInfoComponent,
} from '@pedix-workspace/angular-ui-icons';
import { TranslocoDirective } from '@ngneat/transloco';
import { AppRequestContextService } from '../../../app-request-context.service';
import { AppCartItemsService } from '../../../app-cart-items.service';
import { CheckoutPreloaderService } from '../../order-checkout/checkout-preloader.service';
import {
  CartItemsListComponent,
  EndOrderTotals,
  OrderTotalDetailsComponent,
} from '@pedix-workspace/angular-shared-order';
import { EndOrderService } from '../../order-checkout/end-order/end-order.service';

@UntilDestroy()
@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    IconBackComponent,
    IconAlertComponent,
    ResumeItemComponent,
    CategoryAvailabilityDialogComponent,
    ModalDialogComponent,
    AlertComponent,
    ButtonComponent,
    AsyncPipe,
    CurrencyFormatPipe,
    StockInfoPipe,
    StockIsOutOfStockPipe,
    IconInfoComponent,
    OrderTotalDetailsComponent,
    CartItemsListComponent,
  ],
})
export class ResumeComponent implements OnInit {
  private platformId = inject(PLATFORM_ID);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private analytics = inject(AnalyticsService);
  private appRequestContext = inject(AppRequestContextService);
  private stockService = inject(StockService);
  private establishmentWorkingHours = inject(EstablishmentWorkingHoursService);
  private appCartItemsService = inject(AppCartItemsService);
  private checkoutPreloaderService = inject(CheckoutPreloaderService);
  private endOrderService = inject(EndOrderService);

  @ViewChild('removeCartItemDialog') removeCartItemDialog: ModalDialogComponent;
  @ViewChild(CategoryAvailabilityDialogComponent)
  categoryAvailabilityDialog: CategoryAvailabilityDialogComponent;

  @ViewChild('totalDetailsDialog')
  totalDetailsDialog: ModalDialogComponent;

  endOrderTotals: EndOrderTotals;
  cartItems: CartItem[] = [];
  cartItemToRemove: CartItem;
  isCartDisabled = false;
  stockErrors: StockInfo[] = [];
  categoriesAvailableById: Record<string, { category: ApiV1_Category; isAvailable: boolean }> = {};
  loadingCheckout = false;

  get establishment() {
    return this.appRequestContext.establishment;
  }

  get selectedLanguage() {
    return this.appRequestContext.selectedLanguage;
  }

  get hasProductsWithDisabledCategories(): boolean {
    return this.cartItems.some(cartItem => {
      return this.categoriesAvailableById[cartItem.category.id]?.isAvailable === false;
    });
  }

  get displayTotalDetails() {
    return this.endOrderTotals?.finalAmount !== this.endOrderTotals?.totalAmount;
  }

  get minimumPurchase() {
    return this.establishment.catalogConfiguration.minimumPurchase;
  }

  get hasMinimumPurchaseError(): boolean {
    return this.minimumPurchase && this.minimumPurchase > (this.endOrderTotals?.finalAmount || 0);
  }

  get disableConfirmOrder(): boolean {
    if (
      this.hasMinimumPurchaseError ||
      this.stockErrors.length > 0 ||
      this.hasProductsWithDisabledCategories
    ) {
      return true;
    }
    return false;
  }

  get displaySku(): boolean {
    return this.appRequestContext.shouldDisplaySku;
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.appCartItemsService.cartItemsObservable$
      .pipe(untilDestroyed(this))
      .subscribe(cartItems => {
        if (cartItems.length === 0) {
          this.back();
          return;
        }
        this.cartItems = cartItems;

        this.endOrderTotals = this.endOrderService.getEndOrderTotals({
          cartItems: cartItems,
        });
      });

    this.establishmentWorkingHours.openedStatus$
      .pipe(untilDestroyed(this))
      .subscribe(({ isCartDisabled }) => {
        this.isCartDisabled = isCartDisabled;
      });

    // Checks if all items in cart have enough stock
    this.stockService.stock$.pipe(untilDestroyed(this)).subscribe(stock => {
      this.stockErrors = stock.getItemsInCartWithStockErrors();
    });

    // Loads categories to check availability
    this.categoriesAvailableById = this.appRequestContext.categories.reduce((map, category) => {
      map[category.id] = {
        category,
        isAvailable: isCategoryAvailable(category.availability),
      };
      return map;
    }, {});

    // Preloads checkout dependencies for better UX
    this.checkoutPreloaderService.preloadCheckout();
  }

  back() {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  onEditCartItem(cartItem: CartItem) {
    this.router.navigate([`../resumen/edicion/${cartItem.id}`], {
      relativeTo: this.activatedRoute,
    });
  }

  onRemoveCartItem(cartItem: CartItem) {
    this.cartItemToRemove = cartItem;

    this.removeCartItemDialog.open(async () => this.appCartItemsService.removeCartItem(cartItem));
  }

  onUpdateCartItem(cartItem: CartItem) {
    this.cartItems = this.appCartItemsService.updateCartItem(cartItem);
  }

  goToEndOrder() {
    if (this.disableConfirmOrder) {
      return;
    }
    this.loadingCheckout = true;

    this.analytics.initiateCheckout(this.cartItems, this.endOrderTotals.finalAmount);

    this.router.navigate([`../pedido/`], { relativeTo: this.activatedRoute });
  }

  onClickCategoryNotAvailable(category: CartItemCategory) {
    this.categoryAvailabilityDialog.open(this.categoriesAvailableById[category.id]?.category);
  }

  showTotalDetails() {
    this.totalDetailsDialog.open().catch(() => {});
  }
}
