import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { Announcements } from '@pedix-workspace/utils';
import { IconAdvertisementComponent, IconCloseComponent } from '@pedix-workspace/angular-ui-icons';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';

@Component({
  selector: 'app-announcements-message',
  templateUrl: './announcements-message.component.html',
  styleUrls: ['./announcements-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
  standalone: true,
  imports: [
    TranslocoDirective,
    AlertComponent,
    IconAdvertisementComponent,
    IconCloseComponent,
    ModalDialogComponent,
  ],
})
export class AnnouncementsMessageComponent implements AfterViewInit {
  readonly messageElement = viewChild<ElementRef<HTMLElement>>('messageBody');

  readonly announcements = input<Announcements | null>(null);

  hasMessageOverflow = signal(false);

  announcementsHidden = output<void>();

  ngAfterViewInit() {
    if (this.messageElement()?.nativeElement) {
      const element = this.messageElement().nativeElement;

      this.hasMessageOverflow.set(element.scrollHeight > element.clientHeight);
    }
  }
}
