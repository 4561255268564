<ng-container *transloco="let t">
  <section class="section-resume">
    <div class="ui-title">
      <pxw-icon icon="back" (click)="back()"></pxw-icon>
      {{ t('resume.sectionTitle') }}
    </div>

    <div class="grid-products">
      <pxw-cart-items-list
        [cartItems]="cartItems"
        [undefinedGroupName]="t('product.undefinedGroupName')"
      >
        <ng-template #itemTemplate let-cartItem let-index="index">
          <app-resume-item
            [cartItem]="cartItem"
            [editable]="true"
            [listIndex]="index"
            [selectedLanguage]="selectedLanguage"
            [isCategoryAvailable]="categoriesAvailableById[cartItem.category.id]?.isAvailable"
            [isOutOfStock]="cartItem.product | pxwStockIsOutOfStock | async"
            [stockQty]="(cartItem.product.id | pxwStockInfo | async).item.stockQty"
            [displayItemStockInfo]="true"
            [displaySku]="displaySku"
            (edit)="onEditCartItem($event)"
            (remove)="onRemoveCartItem($event)"
            (update)="onUpdateCartItem($event)"
            (categoryNotAvailable)="onClickCategoryNotAvailable($event)"
          ></app-resume-item>
        </ng-template>
      </pxw-cart-items-list>

      <app-category-availability-dialog></app-category-availability-dialog>

      <pxw-modal-dialog
        #removeCartItemDialog
        [title]="
          t('resume.confirmRemoveFromCartTitle', { cartItemName: cartItemToRemove?.product.name })
        "
        type="confirm"
      >
        <pxw-icon icon="alert" />
        <p>{{ t('resume.confirmRemoveFromCartSubtitle') }}</p>
      </pxw-modal-dialog>
    </div>
    <div class="checkout">
      <div (click)="displayTotalDetails && showTotalDetails()" class="total">
        <p>
          <span>{{ t('global.total') }}:</span>
          @if (displayTotalDetails) {
            <span class="view-total-details">({{ t('endOrder.totalAmountViewDetails') }})</span>
          }
        </p>
        <p class="amount">
          {{ endOrderTotals?.finalAmount | currencyFormat }}
          @if (displayTotalDetails) {
            <span>*</span>
          }
        </p>
      </div>
      @if (isCartDisabled) {
        <pxw-alert type="warning" size="sm">{{ t('global.cartDisabledWhileClosed') }}</pxw-alert>
      } @else {
        <pxw-button
          display="block"
          size="lg"
          (click)="goToEndOrder()"
          [disabled]="disableConfirmOrder"
          [loading]="loadingCheckout"
          [rounded]="true"
        >
          {{
            hasMinimumPurchaseError
              ? t('resume.minimumPurchaseAmountText', { minimumPurchase })
              : t('resume.confirmOrderText')
          }}
        </pxw-button>
      }
    </div>
  </section>
  <pxw-modal-dialog
    #totalDetailsDialog
    [title]="t('totalDetails.title')"
    type="modal"
    [showCloseButton]="true"
  >
    <pxw-icon icon="info" />
    <ng-template #modalContent>
      <app-order-total-details [orderTotals]="endOrderTotals"></app-order-total-details>
    </ng-template>
  </pxw-modal-dialog>
</ng-container>
