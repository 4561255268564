<ng-container *transloco="let t">
  <div class="layout-grid" [ngClass]="{ 'with-subsidiaries': hasSubsidiaries }">
    @if (establishment) {
      <app-header
        [establishment]="establishment"
        [hasCartItems]="hasCartItems"
        [displayAdminButton]="displayAdminButton"
        [selectedLanguage]="selectedLanguage"
        [availableLanguages]="availableLanguages"
        [(searchText)]="searchText"
        (searchTextChange)="onProductSearch($event)"
        (clickLateralMenu)="onClickLateralMenu($event)"
        (languageSelected)="onLanguageSelected($event)"
      ></app-header>
    }
    <div>
      <pxw-drawer [(isOpen)]="sideMenuOpen">
        <ng-template #sideMenu>
          <aside aria-label="Menú secundario">
            <app-side-menu (navItemClick)="onNavItemClick()"></app-side-menu>
          </aside>
        </ng-template>
        <aside>
          @if (displayWorkingHoursMessage) {
            <app-working-hours-message
              [isClosed]="openedStatus.isClosed"
              [isAboutToClose]="openedStatus.isAboutToClose"
              [isTemporaryClosed]="openedStatus.isTemporaryClosed"
              [workingDays]="establishment.workingDays"
              [temporaryClosure]="establishment.temporaryClosure"
            ></app-working-hours-message>
          } @else {
            @if (displayAnnouncements) {
              <app-announcements-message
                [announcements]="establishment.announcements"
                (announcementsHidden)="announcementsHidden = true"
              />
            }
          }
        </aside>
        <main class="layout-router-wrapper">
          <article>
            @if (catalogIsEnabled) {
              <router-outlet class="layout-router-outlet"></router-outlet>
            } @else {
              <div class="catalog-disabled">
                <h6>{{ t('workingHours.weAreClosed') }}</h6>
                @if (establishment?.temporaryClosure?.message) {
                  <p class="subtitle">
                    {{ establishment?.temporaryClosure?.message }}
                  </p>
                }
              </div>
            }
          </article>
          @if (sideMenuOpen) {
            <pxw-modal-history
              name="side-menu"
              title="Menu"
              (popState)="sideMenuOpen = false"
            ></pxw-modal-history>
          }
        </main>
      </pxw-drawer>
    </div>
  </div>

  @if (loadingLanguage) {
    <div class="ui-loader ui-loader--with-overlay">
      <pxw-icon icon="loading" [spin]="true"></pxw-icon>
    </div>
  }
</ng-container>
