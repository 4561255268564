import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="tiktok"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconTiktokComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.801 11.3267C30.4935 9.85244 29.7728 7.95953 29.773 6H23.861V29.4222C23.8163 30.69 23.2746 31.8911 22.3501 32.7722C21.4256 33.6532 20.1906 34.1452 18.9056 34.1444C16.1888 34.1444 13.9311 31.9533 13.9311 29.2333C13.9311 25.9844 17.1071 23.5478 20.3788 24.5489V18.58C13.7781 17.7111 8 22.7733 8 29.2333C8 35.5233 13.2806 40 18.8865 40C24.8941 40 29.773 35.1833 29.773 29.2333V17.3522C32.1703 19.0519 35.0485 19.9639 38 19.9589V14.1222C38 14.1222 34.4031 14.2922 31.801 11.3267Z"
      />
    </svg>
  `,
})
export class IconTiktokComponent extends IconComponent {}
