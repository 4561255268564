<ng-container *transloco="let t">
  @if (product) {
    <section
      class="content-product"
      [ngClass]="{ 'with-presentations': product.presentations, 'cart-disabled': isCartDisabled }"
    >
      <h1 class="ui-title">
        <div class="title-content">
          <pxw-icon icon="back" (click)="back()"></pxw-icon>
          <div>
            @if (displaySku && product.sku) {
              <p class="product-sku">{{ product.sku }}</p>
            }
            <span class="product-title">{{
              product | propInSelectedLanguage: 'name' : selectedLanguage : true
            }}</span>
          </div>
        </div>
        @if (displayShareButton) {
          <pxw-button
            color="theme2-contrast"
            [rounded]="true"
            [href]="productShareUrl"
            [target]="whatsappLinkHttpTarget"
          >
            <pxw-icon icon="share" />
            {{ t('product.share') }}
          </pxw-button>
        }
      </h1>
      <div class="grid-product">
        <div class="product-preview">
          @if (galleryImages.length > 0) {
            <div class="gallery-wrapper">
              <pxw-image-gallery
                [images]="galleryImages"
                [(activeIndex)]="activeGalleryIndex"
                [zoomEnabled]="true"
                (activeIndexChange)="onGalleryImageChange($event)"
                (imageZoom)="onImageZoom($event)"
              ></pxw-image-gallery>
            </div>
          } @else {
            <div class="content-without-image">{{ t('product.withoutImage') }}</div>
          }
          <pxw-image-zoom
            [popupTitle]="imageToZoomTitle"
            [zoomImage]="imageToZoom"
            [(isOpen)]="isZoomOpen"
          ></pxw-image-zoom>

          <!-- Price discount -->
          @if (!product.presentations) {
            @if (product.price | pxwPriceDiscount: product.priceDiscount; as discountPercentage) {
              <pxw-tag class="discount-percentage" type="success" size="sm">{{
                t('product.priceDiscountPercentage', { discountPercentage })
              }}</pxw-tag>
            }
          }
        </div>

        <div class="product-content">
          @if (!product.presentations) {
            <pxw-available-stock
              style="margin-bottom: 1em"
              [stockInfo]="product.id | pxwStockInfo | async"
              [cartItemId]="cartItemId"
              [stockDisplayCondition]="establishment.catalogConfiguration.stockDisplayCondition"
              [stockDisplayConditionValue]="
                establishment.catalogConfiguration.stockDisplayConditionValue
              "
            ></pxw-available-stock>
          }
          <pxw-rich-text
            [text]="product | propInSelectedLanguage: 'description' : selectedLanguage"
          ></pxw-rich-text>
          <form
            id="productForm"
            #productForm="ngForm"
            (keydown.enter)="$event.preventDefault()"
            (ngSubmit)="addToCart($event, productForm, product)"
          >
            @if (product.presentations) {
              <div class="product-presentations">
                <pxw-product-option-selector
                  itemType="presentation"
                  [option]="product.presentations"
                  [(value)]="selectedPresentationOption"
                  (valueChange)="onStockSelectionChanged()"
                  [selectedLanguage]="selectedLanguage"
                  [cartItemId]="cartItemId"
                  [displayErrors]="productForm.submitted"
                  [displaySku]="displaySku"
                  [stockDisplayCondition]="establishment.catalogConfiguration.stockDisplayCondition"
                  [stockDisplayConditionValue]="
                    establishment.catalogConfiguration.stockDisplayConditionValue
                  "
                ></pxw-product-option-selector>
              </div>
            }
            @if (product.options?.length > 0) {
              @for (embeededOption of product.options; track embeededOption; let i = $index) {
                <pxw-product-option-selector
                  itemType="option-item"
                  [option]="embeededOption"
                  [(value)]="selectedOptions[i]"
                  (valueChange)="onStockSelectionChanged()"
                  [cartItemId]="cartItemId"
                  (itemImageClick)="onItemImageClick($event)"
                  [selectedLanguage]="selectedLanguage"
                  [displayErrors]="productForm.submitted"
                  [displaySku]="displaySku"
                  [stockDisplayCondition]="establishment.catalogConfiguration.stockDisplayCondition"
                  [stockDisplayConditionValue]="
                    establishment.catalogConfiguration.stockDisplayConditionValue
                  "
                ></pxw-product-option-selector>
              }
            }
            @if (!isCartDisabled) {
              <div class="input-observations">
                <pxw-input-textarea
                  name="observations"
                  [label]="t('product.observationsLabel')"
                  [(ngModel)]="productObservations"
                  [placeholder]="t('product.observationsPlaceholder')"
                  [maxLength]="150"
                  type="textarea"
                />
              </div>
            }
          </form>
        </div>

        @if (product.tags?.length > 0) {
          <div class="product-tags">
            <hr class="ui-separator ui-separator--sm" />
            <p class="product-tags__title">{{ t('product.relatedProductsTitle') }}</p>
            <div class="product-tags__list">
              @for (
                current of product.linkedProductTagIds | appProductTagsWithQuantities;
                track current
              ) {
                <pxw-product-tag
                  class="ui-clickable"
                  [tag]="current.productTag.tag"
                  [color]="current.productTag.color"
                  [count]="current.count"
                  size="sm"
                  [routerLink]="['/productos-relacionados']"
                  [queryParams]="{ t: current.productTag.id }"
                />
              }
            </div>
          </div>
        }
      </div>
      <app-product-footer
        [isCartDisabled]="isCartDisabled"
        [isWithoutStock]="isWithoutStock"
        [isCategoryAvailable]="category | isCategoryAvailable$ | async"
        [category]="category"
      >
        @if (!product.presentations?.displayItemQuantities) {
          <div>
            <pxw-counter
              [(count)]="productQuantity"
              (countChange)="onStockSelectionChanged()"
              [max]="maxQuantity"
            ></pxw-counter>
          </div>
        }
        <pxw-button
          mode="submit"
          formId="productForm"
          color="yellow"
          size="lg"
          [rounded]="true"
          [disabled]="hasStockIssues"
        >
          <span class="add-product-label">{{
            editMode ? t('product.update') : t('product.add')
          }}</span>
          <pxw-product-price
            [price]="cartItemSubtotal"
            [priceDiscount]="cartItemSubtotalWithDiscount"
            layout="product-detail"
          />
        </pxw-button>
      </app-product-footer>
    </section>
  } @else {
    <div class="ui-loader">
      <pxw-icon icon="loading" [spin]="true"></pxw-icon>
    </div>
  }
</ng-container>
